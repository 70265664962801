.events {
    .top-banner {
        background-image: url(./../../img/frontend/events-banner.png);
    }

    .bg-map {
        background-image: url(./../../img/frontend/kraken.png);
        background-position: bottom left;
        background-repeat: no-repeat;
        padding-top: 110px;
    }
    .events-container {
        .latest-post-sections {
            background-image: url(./../../img/frontend/fish.png);
            background-position: top right;
            background-repeat: no-repeat;
        }
        .search-form {
            background: #fff;
            padding: 35px 40px;
            box-shadow: 0px 6px 44.16px 3.84px rgba(224, 237, 245, 0.35);
            border-radius: 5px;
            background-image: url(./../../img/frontend/events-search-bg.png);
            background-position: bottom right;
            background-repeat: no-repeat;
            h5 {
                font-size: 27px;
                font-family: "Futura Demi";
                color: @main-dark;
                line-height: 1.5;
            }
            form .flex-end {
                display: flex;
                align-items: flex-end;
            }
            .form-group {
                margin-bottom: 0;
            }
            label {
                font-size: 18px;
                color: @main-dark;
            }
            button[type="submit"] {
                background-color: @bg-orange;
                outline: none;
                border: none;
            }
            input:not([type="submit"]),
            select {
                border-radius: 3px;
                background-color: #F2F6F7;
                border: none;
            }
            .select2 {
                .select2-style;
            }
        }
        #event-carousel {
            margin-top: 50px;
            margin-bottom: 90px;
            li {
                .general-transition;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100%;
                min-height: 420px;
                padding: 20px;
                margin: 20px;
                margin-bottom: 25px;
                position: relative;
                overflow: hidden;
                border-radius: 7px;
                &:after {
                    .general-transition;
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: -100px;
                    left: 0;
                    background-image: linear-gradient(to top, @dashboard-header-bg 5px, rgba(8, 49, 79, 0.49) 50%, transparent 100%);
                    cursor: pointer;
                    pointer-events: none;
                }
                a {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    z-index: 2;
                    span {
                        .general-transition;
                        text-decoration: none;
                        color: #fff;
                        font-size: 26px;
                        font-family: "Arno Pro", sans-serif;
                        position: relative;
                        z-index: 2;
                    }
                }
                &:hover {
                    .general-transition;
                    background-size: 102%;
                    box-shadow: 0px 10px 30px 0px rgba(34, 54, 85, 0.3);
                    &:after {
                      bottom: 0;
                    }
                    a {
                        &:hover {
                            text-decoration: none;
                        }
                        span {
                            font-size: 33px;
                        }
                    }
                }
            }
            .owl-page {
                span {
                    background: #dee8ee;
                }
                &.active span {
                    background: @bg-orange;
                }
            }
        }
        .label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 35px;
            .label-box {
                width: calc(100% - 100px);
                h3:after {
                    left: 13%;
                }
            }
        }
        #events-listing {
            .thumbnail {
                width: 100%;
                border-radius: 7px;
            }
            .content {
                padding: 10px;
                .posted-by {
                    margin-bottom: 40px;
                    img {
                        border-radius: 50%;
                        margin-right: 15px;
                    }
                    .name {
                        color: @bg-orange;
                    }
                }
                .address {
                    margin-left: 30px;
                    i {
                        color: @bg-orange;
                    }
                }
                .item-content {
                    color: @bg-footer;
                    padding: 7px 0;
                }
            }
            // GRID
            &.view-grid {
                .item {
                    position: relative;
                    flex: 1 1 50%;
                    margin-right: 1.5%;
                    display: inline-block;
                    padding: 0;
                    border: 0 !important;
                    border-radius: 7px;
                    box-shadow: 0px 10px 40px 0px rgba(34, 54, 85, 0.07);
                    width: 32%;
                    margin-bottom: 1.5%;
                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                    .content,
                    .image {
                        flex: none;
                        padding: 0;
                        max-width: 100%;
                        width: 100%;
                    }
                    .content {
                        padding: 30px 10px;
                        .posted-by {
                            margin-bottom: 10px;
                        }
                        .address {
                            margin-left: 0;
                            display: block;
                            margin-top: 10px;
                        }
                        .item-content {
                            height: 85px;
                            overflow: hidden;
                        }
                        h4 a {
                            display: block;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
}

// Inner
.event-details {
    .component-box {
        margin-top: 0;
        margin-bottom: 0;
    }
    .main-content {
        background: #fff;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(34, 54, 85, 0.04), 0px 1px 40px 0px rgba(34, 54, 85, 0.03);
        -moz-box-shadow: 0px 0px 4px 0px rgba(34, 54, 85, 0.04), 0px 1px 40px 0px rgba(34, 54, 85, 0.03);
        box-shadow: 0px 0px 4px 0px rgba(34, 54, 85, 0.04), 0px 1px 40px 0px rgba(34, 54, 85, 0.03);
        .event-details-banner {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 80vh;
            .gradient {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                background-image: linear-gradient(to top, #fff 5px, rgba(255, 255, 255, 0.4) 15%, transparent 100%);
            }
            .info-block {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: baseline;
                margin-bottom: 20px;
            }
            .banner-share {
                display: flex;
                align-items: center;
                span {
                    margin-left: 15px;
                    margin-right: 15px;
                }
            }
            .category {
                background: @bg-orange;
                padding: 1px 10px;
                border-radius: 3px;
                color: #fff;
                margin-left: 15px;
            }
        }
        .under-banner {
            display: flex;
            justify-content: space-between;
            min-height: 60px;
            align-items: center;
            span {
                color: @bg-orange;
            }
            a {
                color: @bg-orange;
            }
        }
        .content {
            padding-bottom: 60px;
            h1 {
                text-align: center;
                font-weight: bold;
            }
        }
    }
    .side-bar {
        .bar-calendar-widget {
            margin-bottom: 40px;
        }
    }
    #event-map {
        .gmnoprint,
        .gm-bundled-control {
            display: none;
        }
    }
}


@media only screen and (max-width: 992px) {

    body.events {
        .events-container {
            .search-form {
                text-align: center;
                div[class*='col-'] {
                    padding: 0!important;
                    margin: 5px 0;
                }
            }
            .label .label-box {
                width: 100%;
            }
            .view-switcher-outer {
                display: none;
            }
            #events-listing.view-grid,
            #events-listing.view-list {
                .view-switcher {
                    display: none;
                }
                .item {
                    position: relative;
                    flex: 1 1 50%;
                    margin-right: 0;
                    display: inline-block;
                    padding: 0;
                    border: 0 !important;
                    border-radius: 7px;
                    box-shadow: 0px 10px 40px 0px rgba(34, 54, 85, 0.07);
                    width: 100%;
                    margin-bottom: 25px;
                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                    .content,
                    .image {
                        flex: none;
                        padding: 0;
                        max-width: 100%;
                        width: 100%;
                    }
                    .content {
                        padding: 30px 10px;
                        .posted-by {
                            display: flex;
                            align-items: flex-start;
                            margin-bottom: 10px;
                            .category {
                                bottom: 0;
                                top: auto;
                                left: 85px;
                                right: auto;
                            }
                        }
                        .address {
                            margin-left: 0;
                            display: block;
                            margin-top: 10px;
                        }
                        .item-content {
                            height: 85px;
                            overflow: hidden;
                        }
                        h4 a {
                            display: block;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
    body.events.event-details {
        .event-details-banner .info-block {
            display: inline;
            padding-left: 20px;
            &> div {
                margin-bottom: 10px;
            }
            .banner-share {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: flex-start;
                .fa-link {
                    display: none;
                }
                span {
                    margin: 0;
                    margin-bottom: 10px;
                }
            }
        }
        .under-banner {
            align-items: flex-start;
            flex-direction: column;
        }

    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    body.events.event-details {
        .event-details-banner .info-block {
            display: inline;
            padding-left: 20px;
            &> div {
                margin-bottom: 10px;
            }
            .banner-share {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: flex-start;
                .fa-link {
                    display: none;
                }
                span {
                    margin: 0;
                    margin-bottom: 10px;
                }
            }
        }
        .under-banner {
            align-items: flex-start;
            flex-direction: column;
        }

    }
}