.tree-view {
    /* Remove default bullets */

    ul {
        list-style-type: none;
        padding: 0;
    }

    /* Style the caret/arrow */

    .caret {
        cursor: pointer;
        user-select: none; /* Prevent text selection */
    }

    /* Create the caret/arrow with a unicode, and style it */

    .caret::before {
        content: "\25B6";
        color: black;
        display: inline-block;
        margin-right: 6px;
    }

    /* Rotate the caret/arrow icon when clicked on (using JavaScript) */

    .caret-down::before {
        transform: rotate(90deg);
    }

    /* Hide the nested list */

    .nested {
        display: none;
    }

    /* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */

    .active {
        display: block;
    }

    .btn {
        padding: 0 8px!important;
        .label-remove {
            display: none;
        }
        &.btn-remove {
            .label-add {
                display: none;
            }
            .label-remove {
                display: inline-block;
            }
        }
    }
}