.sortable {
    > * > .sortable-item {
        position: relative;
        .sortable-handle {
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
            width: 20px;
            height: 20px;
            text-align: center;
            .fa-sort {
                transform: rotate(90deg);
            }
        }
        .remove-handle {
            color: #EF6F6C;
            position: absolute;
            bottom: 5px;
            right: 5px;
            cursor: pointer;
            width: 20px;
            height: 20px;
            text-align: center;
        }
    }
}