#search-service-areas-widget {
    padding: 15px 0;
    .search-by-location-widget;
}

#service-areas-picker-widget {
    padding: 15px 0;
    .form-group {
        label {
            width: 120px;
            display: inline-block;
        }
        input.form-control {
            display: inline-block;
            width: 280px!important;
        }
    }
}