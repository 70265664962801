.select2-style {
    &.select2-container {
        &--focus, &--open {
            .select2-selection {
                border: 1px solid #99A3A4;
                opacity: 0.66;
                box-shadow: inset 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.17);
            }
        }
    }
    .select2-selection {
        height: 38px !important;
        border-radius: 2px;
        background: #f3f7f8;
        border: 1px solid #f3f7f8;
        box-shadow: none;
        .select2-selection__rendered {
            line-height: 27px !important;
        }
    }
}
.select2-container {
    z-index: 1029;
}