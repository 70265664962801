.jobs-wizard {
    .jobs-member-selector {
        padding: 25px 30px;
        background: #fff;
        border-radius: 7px;

        .search-form {
            padding: 0 !important;

            form {
                padding: 0;
            }
        }

        .member-selector-widget {
            margin-bottom: 0;
        }
    }
}

.jobs {
    background-color: #e7edf0;

    .bg-map {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    &.review-job {
        .bg-map {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    #jobs-listing {
        margin-top: 70px;

        .item {
            .content {
                .posted-by {
                    margin-bottom: 15px;
                }

                .date {
                    margin-right: 15px;
                }
            }
        }
    }

}

.event-details.job-details {
    .bg-map {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .main-content {
        margin-bottom: 60px;

        .content {
            padding: 30px;

            h1 {
                text-align: left;
            }

            .job-details-top-info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .left-side {
                    display: flex;
                    //padding: 30px 0;
                    img {
                        margin-top: 6px;
                        margin-right: 15px;
                        width: 25px;
                        height: 25px;
                    }

                    .title-section {
                        .boat,
                        .address {
                            display: inline-block;
                            margin-right: 10px;
                        }
                    }
                }

                .right-side {
                    .category {
                        background: #f8b42b;
                        padding: 1px 10px;
                        border-radius: 3px;
                        color: #fff;
                        margin-left: 15px;
                    }
                }
            }

            .info-block {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: baseline;
                margin-top: 30px;

                .posted-by {
                    img {
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        border-radius: 50%;
                        margin-right: 15px;
                    }
                }

                .right-share-info {
                    display: flex;
                    align-items: baseline;

                    > div {
                        display: flex;
                        align-items: center;

                        .share {
                            margin-left: 15px;
                        }
                    }
                }
            }
        }

        .apply-btn {
            margin-left: 15px;
            text-align: right;

        }
    }

    #job-map {
        .gmnoprint,
        .gm-bundled-control {
            display: none;
        }
    }
}

@media only screen and (max-width: 1199px) {

    .event-details.job-details {
        .main-content {
            .event-details-banner {
                height: 300px;
            }

            .content {
                .job-details-top-info {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    padding-top: 30px;

                    .left-side {
                        margin-bottom: 30px;

                        img {
                            position: absolute;
                            top: 0;
                            right: 0;
                            margin: 0;
                        }

                        .title-section {
                            text-align: center;
                        }
                    }
                }

                .info-block {
                    flex-direction: column;
                    align-items: center;

                    .right-share-info {
                        margin-top: 10px;
                        flex-direction: column;
                        align-items: center;

                        .banner-share {
                            flex-direction: column;

                            & > * {
                                margin-bottom: 15px;
                            }
                        }

                        .apply-btn {
                            margin-left: 0;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {

    .event-details.job-details {
        .main-content {

        }

        #job-map {
            margin-bottom: 30px;
        }

        .side-bar {
            padding: 0 15px;
        }
    }

}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
}


.search-jobs {
    .top-banner {
        background-image: url(./../../img/frontend/search-jobs-banner.png);
    }

    .items {
        margin-top: 60px;

        .item {
            margin-bottom: 30px;

            > a {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                height: 420px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                position: relative;
                overflow: hidden;
                text-decoration: none;
                -webkit-border-radius: 7px;
                -moz-border-radius: 7px;
                border-radius: 7px;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: -100px;
                    left: 0;
                    background-image: linear-gradient(to top, #08314f 5px, rgba(8, 49, 79, 0.49) 50%, transparent 100%);
                }

                h3 {
                    margin-bottom: 20px;
                    color: #fff;
                    position: relative;
                    z-index: 2;
                    text-decoration: none;
                }
            }
        }
    }
}

.dashboard-jobs {
    .nav.nav-tabs {
        margin: 0 0 0 30px !important;

        .nav-item {
            a {
                padding: 0.375rem 0.75rem;
                min-width: 80px;
            }
        }
    }

    .dashboard-table {
        .actions {
            height: 118px;
        }
    }
}

#start-making-job {
    label {
        margin-bottom: 0;
        padding: 5px 25px 5px 0;
        line-height: 29px;
    }

    #account-type-input {
        margin-right: 25px;
    }

    #services-input {
        width: 400px;
    }
}

.dashboard {
    aside {
        .dashboard-nav li a.job-aside-item {
            span:first-child {
                .counters {
                    display: inline-block;
                    float: right;
                    margin-right: 10px;

                    > span {
                        font-size: 12px;
                        border-radius: 3px;
                        font-weight: 700;
                        line-height: 1;
                        text-align: center;
                        white-space: nowrap;
                        vertical-align: baseline;
                        padding: 0.25em 0.4em;
                        display: inline-block;
                        color: #fff;
                        background: #ececec;

                        &.published {
                            background: #F7A401;
                        }

                        &.in_process {
                            background: #0B9F5D;
                        }

                        &.completed {
                            background: #DC5341;
                        }
                    }
                }
            }
        }
    }
}

.favorites-jobs {
    .dashboard-table-view {
        .search-form {
            .white-content-block;
        }
    }
}

.dashboard-jobs-search {
    margin: 0 0 0 30px;
    position: relative;
    top: 2px;
    width: 400px;

    .results {
        position: relative;

        .results-list {
            display: block;
            position: absolute;
            top: 1px;
            left: 0;
            right: 0;

            z-index: 1000;
            padding: 0.5rem 0;
            margin: 0.125rem 0 0;
            font-size: 1rem;
            color: #212529;
            text-align: left;
            list-style: none;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 0.25rem;

            li {
                cursor: pointer;
                display: block;
                width: 100%;
                padding: 0.25rem 1.5rem;
                font-weight: 400;
                color: #212529;
                text-align: inherit;
                white-space: nowrap;
                text-overflow: ellipsis;
                background-color: transparent;
                border: 0;

                &:hover {
                    text-decoration: none;
                    background-color: #f8f9fa;
                }
            }
        }

        .dropdown-submenu {
            position: relative;
        }

        .dropdown-submenu a::after {
            transform: rotate(-90deg);
            position: absolute;
            right: 6px;
            top: .8em;
        }

        .dropdown-submenu .dropdown-menu {
            top: 0;
            left: 100%;
        }

        .dropdown-item {
            padding: 0;
            &:active {
                color: @dashboard-dropdown-color;
                background-color: #f8f9fa!important;
            }
        }
    }
}

@import "./tickets";