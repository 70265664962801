.attachments {
    .white-content-block;
    padding: 20px 10px;
    h4 {
        font-family: 'Futura Demi', san-serif;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 20px;
        padding: 0 15px 15px;
    }
    .attachments-list {
        list-style: none;
        padding: 20px;
        li {

        }
    }
}