.primary-vessel-widget {
    .vessel {
        color: @dashboard-dropdown-color;
        img {
            display: block;
            width: 100%;
        }
        label {
            cursor: pointer;
            display: block;
            margin-bottom: 0;
            margin-top: 10px;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
        }
        &:hover,
        &:focus {
            text-decoration: none;
            color: @bg-orange;
        }
    }
}