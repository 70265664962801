.dashboard-tickets {
    .nav.nav-tabs {
        margin: 0 0 0 30px !important;

        .nav-item {
            a {
                padding: 0.375rem 0.75rem;
                min-width: 80px;
            }
        }
    }

    .dashboard-table {
        .actions {
            height: 118px;
        }
    }
}