.forgot-password {
    .white-block {
        .white-content-block;
        padding: 30px;
        text-align: center;
    }
    .forgot-password-container {
        margin-top: 95px;
        margin-bottom: 95px;
        h1 {
            font-size: 40px;
            font-family: 'Futura Demi';
            margin-bottom: 20px;
            color: @main-dark;
        }
        p {
            font-size: 22px;
            color: @bg-footer;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &-form {
        h2 {
            font-size: 40px;
            font-family: 'Futura Demi';
            margin-top: 20px;
            margin-bottom: 40px;
        }
        background: #fff;
    }
}