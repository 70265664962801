.email-confirmed {
    .top-banner {
        background-image: url(./../../img/frontend/signup-banner.jpg);
    }

    .confirmation-container {
        margin-top: 95px;
        margin-bottom: 95px;

        .white-block {
            .white-content-block;
            padding: 30px;
            p {
                text-align: center;
            }
        }

        h1 {
            font-size: 40px;
            font-family: 'Futura Demi';
            margin-bottom: 20px;
            color: @main-dark;
        }

        p {
            font-size: 22px;
            color: @bg-footer;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}