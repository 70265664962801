.content-page {
    background: @bg-lite;
    .bg-map {
        .decor-1 {
            background-image: url(./../../img/frontend/bg-decoration-1.jpg);
            background-repeat: no-repeat;
            background-position: top right;
            min-height: 687px;
        }
        .decor-2 {
            background-image: url(./../../img/frontend/bg-decoration-2.jpg);
            background-repeat: no-repeat;
            background-position: top left;
            min-height: 687px;
        }
        h1, h2 {
            font-size: 42px;
            font-weight: 600;
            color: @main-dark;
            font-family: 'Arno Pro';
        }
        p {
            font-family: 'Myriad Pro';
            font-size: 17px;
            color: @bg-footer;
        }
        hr {

        }
        hr.short {
            background-color: @main-dark;
            width: 63px;
            margin-left: 0;
            margin-top: 4px;
            margin-bottom: 17px;
        }
    }
}
