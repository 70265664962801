.signup-plans {
    .top-banner {
        background-image: url(./../../img/frontend/signup-banner.jpg);
    }
    .bg-map {
        .white-block {
            .white-content-block;
            padding: 30px;
        }
        h2 {
            font-size: 40px;
            font-family: 'Futura Demi';
            margin-top: 20px;
            margin-bottom: 40px;
            text-align: center;
        }
        form {
            .form-style;
        }
        .signup-plan {
            label {
                margin-bottom: 0;
            }
            margin-bottom: 15px;
        }
        .actions {
            text-align: center;
        }
        #dropin-container {

        }
    }
}
