.about-us {
    .top-banner {
        background-image: url(./../../img/frontend/about-us-banner.jpg);
    }
    .bg-map {
        .white-featured-block {
            background-color: #fff;
            .column {
                padding: 0;
                position: relative;
                .block-content {
                    padding-bottom: 18px;
                    p:last-child {
                        margin-bottom: 0;
                    }
                }
                .ge-content {
                    position: relative;
                    height: 100%;
                    .left-bg {
                        background-image: url(./../../img/frontend/about-01.jpg);
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                    }
                    .right-bg {
                        background-image: url(./../../img/frontend/about-02.jpg);
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                    }
                }
                &.text-block {
                    padding: 75px 90px;
                }
                .btn {
                    width: 178px;
                    height: 51px;
                    line-height: 38px;
                    font-size: 19px;
                }
            }
        }
        .wide-bg-image {
            margin: 70px -15px;
            height: 687px;
            background-image: url(./../../img/frontend/bg-about-us.jpg);
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}