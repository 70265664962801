@sidebar-min-width: 62px;

@dashboard-breadcrumbs-color: #b2bfd2;

@dashboard-dropdown-color: #212529;
@dashboard-dropdown-color-hover: #16181b;
@dashboard-dropdown-bg: #ffffff;
@dashboard-dropdown-hover: #f8f9fa;

.dashboard-block {
    padding: 25px 30px;
    color: @main-dark;
    h4 {
        font-family: 'Futura Book';
        font-size: 20px;
        font-weight: bold;
    }
}

.dashboard-current-weather {
    background-image: url(./../../img/frontend/bg-weather.png);
    background-size: cover;
}

.dashboard-breadcrumbs {
    background: transparent;
    font-size: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
    .breadcrumb-home {
        height: 16px;
        span {
            font-size: 14px;
            color: @dashboard-breadcrumbs-color;
            display: inline-block;
            line-height: 16px;
        }
    }
    border-radius: 0;
    font-family: "Futura Book";
    color: @dashboard-breadcrumb-text;
    li:before {
        font-family: 'icomoon';
        font-size: 8px;
        color: @dashboard-breadcrumbs-color;
        transform: rotate(270deg);
        box-sizing: content-box;
        vertical-align: middle;
        display: inline-block;
        content: "\e900";
        width: 9px;
        height: 16px;
        padding-right: 0;
        margin-right: 6px;
        line-height: 14px;
    }
    li:first-child:before {
        display: none;
    }
    .breadcrumb-item.active {
        color: @dashboard-breadcrumb-text;
    }
    .breadcrumb-item {
        line-height: 14px;
        padding-left: 7px;
        a, span {
            vertical-align: bottom;
            color: @dashboard-breadcrumb-text;
            &:focus, &:hover, &:active {
                color: @dashboard-breadcrumb-text;
            }
        }
    }
}

.dashboard-user-status {
    border-style: solid;
    border-width: 3px;
    border-color: @dashboard-header-bg;
    background-color: #ffffff;
    position: absolute;
    right: 0;
    top: 2px;
    width: 13px;
    height: 13px;
    &.online {
        background-color: rgb(122, 225, 31);
    }
    &.offline {
        background-color: rgb(251, 98, 87);
    }
    .rounded-avatar
}

.dashboard-notification-status {
    border-radius: 50%;
    position: absolute;
    right: -5px;
    top: -3px;
    width: 7px;
    height: 7px;
    &.unreaded {
        background-color: rgb(251, 98, 87);
    }
}

.dashboard-notification {
    position: relative;
    > a {
        position: relative;
        line-height: 21px;
        .dashboard-header-icon;
        .status {
            .blink;
            .dashboard-notification-status;
        }
        display: inline-block;
        margin-left: 18px;
        &:first-child {
            margin-left: 0;
        }
    }
    .notification-dropdown,
    .messages-dropdown {
        position: absolute;
        top: 30px;
        right: 0;
        width: 280px;
        display: none;
        .dashboard-dropdown;
        ul {
            margin: 0 0 .5rem 0;
            a {
                .title {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .view-all {
            display: block;
            text-align: center;
            margin: 0;
        }
    }
    margin: 0 10px;
}

.dashboard-photo {
    position: relative;
    display: inline-block;
    img {
        border-radius: 50%;
    }
    .status {
        .dashboard-user-status;
    }
}

.dashboard-header-icon {
    display: block;
    font-size: 21px;
    color: #b7cad1;
    &:hover {
        color: #b7cad1;
        text-decoration: none;
    }
}

.dashboard-dropdown {
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    background-color: @dashboard-dropdown-bg;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    color: #212529;
    a {
        display: block;
        width: 100%;
        padding: .25rem 1.5rem;
        clear: both;
        font-weight: 400;
        color: @dashboard-dropdown-color;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        &:hover {
            color: @dashboard-dropdown-color-hover;
            text-decoration: none;
            background-color: @dashboard-dropdown-hover;
        }
    }
}

.dashboard {
    .navbar-nav {
        .site-navbar-nav;
    }
    aside {
        width: @sidebar-min-width;
        float: left;
        box-shadow: 0px 6px 44.16px 3.84px rgba(224, 237, 245, 0.35);
        background-color: #ffffff;
        & .aside-top {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            padding: 20px 20px 0;
            & .nav-link {
                color: @main-dark;
            }
            & .toogle-bar {
                cursor: pointer;
                border-radius: 50%;
                width: 25px;
                height: 25px;
                text-align: center;
                .general-transition;
                &:hover {
                    background-color: #efefef;
                    box-shadow: 0px 0px 0px 10px #efefef;
                }
            }
        }
        & .aside-profile {
            display: flex;
            flex-direction: column;
            align-items: center;
            .general-transition;
            padding-bottom: 30px;
            & .status {
                opacity: 1;
                .dashboard-notification-status;
                right: 20px;
                top: 10px;
                height: 20px;
                width: 20px;
                border-style: solid;
                border-width: 3px;
                border-color: #fff;
                &.online {
                    background-color: rgb(122, 225, 31);
                }
                &.offline {
                    background-color: rgb(251, 98, 87);
                }
            }
            & .photo {
                position: relative;
                border-radius: 50%;
                .general-transition;
                &:hover {
                    box-shadow: 0px 0px 40px 10px #efefef;
                }
                img {
                    border-radius: 50%;
                    width: 170px;
                    .general-transition;
                }
                &-boat {
                    border-radius: 4px;
                    img {
                        border-radius: 4px;
                    }
                }
                &-business {
                    border-radius: 50%;
                    img {
                        border-radius: 50%;
                    }
                }
            }
            & a {
                color: @main-dark;
                .general-transition;
                text-decoration: none;
                &:hover {
                    color: @bg-orange;
                }
                &.welcome {
                    font-size: 22px;
                    font-weight: bold;
                    margin-top: 10px;
                }
                &.vessel-info {
                    font-size: 18px;
                    line-height: 20px;
                    color: @grey-text;
                    &:hover {
                        color: @bg-footer;
                    }
                }
            }
            .member-position {
                font-size: 18px;
                line-height: 20px;
                color: @grey-text;
            }
            .member-id {
                font-size: 14px;
                line-height: 22px;
            }
            .member-rating {
                margin-top: 6px;
                .rating {
                    font-size: 14px;
                    line-height: 14px;
                }
            }
        }
        & .connections {
            .general-transition;
            border-top: 1px solid @grey-border;
            padding: 50px 50px;
            visibility: visible;
            //height: 100%;
            opacity: 1;
            overflow: hidden;
            & .aside-connections {
                color: @main-dark;
                > a, .block-header {
                    color: @main-dark;
                    text-decoration: none;
                    display: flex;
                    justify-content: space-between;
                    line-height: 24px;
                    &:hover {
                        color: @main-dark;
                    }
                    > span:first-child {
                        font-size: 24px!important;
                        font-weight: bold;
                    }
                    > span:last-child {
                        font-size: 22px;
                    }
                }
                .rows {
                    margin: 0;
                    li {
                        margin: 30px 0 0 0;
                        height: 100%;
                        div {
                            .general-transition;
                            transform: scale(1);
                            display: block;
                        }
                        a {
                            .general-transition;
                            transform: scale(1);
                            display: block;
                            text-align: center;
                            &:hover {
                                .name {
                                    span:first-child {
                                        color: @bg-orange;
                                    }
                                }
                                text-decoration: none;
                            }
                        }
                        .photo {
                            .dashboard-photo;
                            .status {
                                .general-transition;
                                top: 5px;
                                right: 5px;
                                border-color: #fff;
                                opacity: 1;
                            }
                            padding-bottom: 15px;
                        }
                        .name {
                            text-align: center;
                            color: @main-dark;
                            span:first-child {
                                transition: all .3s ease-in-out;
                                font-weight: bold;
                            }
                            span {
                                font-size: 17px;
                            }
                            small {
                                color: @grey-text;
                                font-size: 13px;
                                text-transform: capitalize;
                                min-height: 19px;
                            }
                            span, small {
                                display: block;
                            }
                        }
                    }
                }
            }
            &.members-profiles {
                .btn-create {
                    .fas {
                        font-size: 15px!important;
                    }
                }
            }
        }
        & .sub-profiles {
            .general-transition;
            border-top: 1px solid @grey-border;
            padding: 50px 50px;
            visibility: visible;
            //height: 100%;
            opacity: 1;
            overflow: hidden;
            & .aside-sub-profiles {
                color: @main-dark;
                > a {
                    color: @main-dark;
                    text-decoration: none;
                    display: flex;
                    justify-content: space-between;
                    line-height: 24px;
                    &:hover {
                        color: @main-dark;
                    }
                    > span:first-child {
                        font-size: 24px;
                        font-weight: bold;
                    }
                    > span:last-child {
                        font-size: 22px;
                    }
                }
                .rows {
                    margin: 0;
                    li {
                        &.add-profile {
                            margin: 15px 0 0 0;
                        }
                        height: 100%;
                        div {
                            .general-transition;
                            transform: scale(1);
                            display: block;
                        }
                        a.member {
                            .general-transition;
                            transform: scale(1);
                            display: block;
                            text-align: center;
                            &:hover {
                                .name {
                                    span:first-child {
                                        color: @bg-orange;
                                    }
                                }
                                text-decoration: none;
                            }
                        }
                        .member-status {
                            padding-top: 2px;
                            margin-right: 10px;
                            .status {
                                .dashboard-notification-status;
                                display: inline-block;
                                position: static;
                                right: 0;
                                top: 0;
                                height: 20px;
                                width: 20px;
                                border-style: solid;
                                border-width: 3px;
                                border-color: #fff;
                                &.online {
                                    background-color: rgb(122, 225, 31);
                                }
                                &.offline {
                                    background-color: rgb(251, 98, 87);
                                }
                            }
                        }
                        .member-name {
                            text-align: center;
                            color: @main-dark;
                            span:first-child {
                                transition: all .3s ease-in-out;
                                font-weight: bold;
                            }
                            span {
                                font-size: 16px;
                                display: block;
                            }
                        }
                        .member-position {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
        & .dashboard-nav {
            padding: 50px 0;
            border-top: 1px solid @grey-border;
            li {
                margin-top: 10px;
                &:first-child {
                    margin-top: 0;
                }
                a {
                    transition: all .3s linear;
                    display: block;
                    padding: 0 52px;
                    border-left: 5px solid transparent;
                    font-size: 21px;
                    color: #5b6b80;
                    //display: flex;
                    //justify-content: space-between;
                    & > span:first-child {
                        width: calc(100% - 30px);
                        display: inline-block;
                    }
                    &:hover {
                        border-left: 5px solid @bg-orange;
                        box-sizing: border-box;
                        color: @bg-orange;
                        text-decoration: none;
                    }
                    .item-icon {
                        //float: right;
                        width: 21px;
                        text-align: center;
                        &-dropdown {
                            font-size: 14px;
                            width: 21px;
                            text-align: center;
                            height: 21px;
                            line-height: 21px;
                            margin-top: 5px;
                            transition: transform .4s;
                        }
                        &.fa-hands-helping,
                        &.fa-briefcase,
                        &.fa-sign-out-alt {
                            font-size: 18px;
                        }
                        &.fa-ship {
                            font-size: 18px;
                            &:before {
                                margin-left: -1px;
                            }
                        }
                        &.fa-star {
                            font-size: 19px;
                        }
                        &.fa-users {
                            font-size: 18px;
                        }
                        &.fa-users-cog {
                            font-size: 18px;
                        }
                        &.fa-ticket-alt {
                            font-size: 18px;
                        }
                        &.fa-archive,
                        &.fa-book {
                            font-size: 18px;
                        }
                    }
                }
                ul {
                    li {
                        a {
                            span:first-child {
                                width: calc(100% - 57px);
                                display: inline-block;
                            }
                            &:before {
                                content: '';
                                display: inline-block;
                                width: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
    .aside-opened {
        .dashboard-nav {
            & > li {
                position: relative;
                & > a {
                    .badge {
                        font-size: 10px;
                        position: absolute;
                        top: 0;
                        right: 46px;
                    }
                }
            }
        }
        .dashboard-nav-favorites {
            > a {
                .for-opened {
                    display: inline;
                }
                .for-closed {
                    display: none;
                }
                .item-icon {
                    margin-left: 4px;
                }
                &.collapsed {
                    .item-icon-dropdown.for-opened {
                        transform: rotate(90deg);
                    }
                }
            }
            > ul {
                margin-top: 10px;
            }
        }
        ul {
            li {
                .badge {
                    margin-right: 3px;
                }
            }
        }
    }
    .aside-closed {
        aside {
            #sidebar-toggle {
                transform: scale(-1, 1);
            }
            #language-switcher {
                display: none;
            }
            .aside-profile {
                padding: 15px 0;
                .photo {
                    .status {
                        //right: 0;
                        //top: 0;
                        //height: 15px;
                        //width: 15px;
                        opacity: 0;
                    }
                    img {
                        width: 45px;
                    }
                    &-boat {
                        border-radius: 50%;
                        img {
                            border-radius: 50%;
                        }
                    }
                }
                > *:not(.photo) {
                    display: none;
                }
            }
            .connections {
                visibility: hidden;
                height: 0;
                padding: 0;
                opacity: 0;
                a {
                    display: none;
                }
                & .aside-connections {
                    .rows {
                        li {
                            margin: 0px;
                            height: 0px;
                            div {
                                transform: scale(0);
                            }
                        }
                    }
                }
            }
            .sub-profiles {
                visibility: hidden;
                height: 0;
                padding: 0;
                opacity: 0;
                a {
                    display: none;
                }
                & .aside-sub-profiles {
                    .rows {
                        li {
                            margin: 0px;
                            height: 0px;
                            div {
                                transform: scale(0);
                            }
                        }
                    }
                }
            }
            .dashboard-nav {
                & > li {
                    position: relative;
                    & > a {
                        padding: 0 19px 0 14px;
                        text-align: center;
                        & span:first-child {
                            display: none;
                        }
                        &[data-toggle="collapse"] {
                            min-height: 34px;
                        }
                        &[aria-expanded="true"] {
                            border-left: 5px solid #f8b42b;
                            color: #f8b42b;
                            display: flex;
                            align-items: baseline;
                            justify-content: center;
                            .icomoon:before {
                                transform: rotate(-90deg);
                                display: block;
                            }
                        }
                        .badge {
                            font-size: 10px;
                            position: absolute;
                            top: 0;
                            right: 12px;
                        }
                    }
                    & > ul {
                        background-color: @dashboard-header-bg;
                        position: absolute;
                        left: 62px;
                        top: 0;
                        a {
                            display: flex;
                            justify-content: space-between;
                            padding: 10px 15px;
                            align-items: center;
                            span:first-child {
                                min-width: 100px;
                                margin-right: 20px;
                                color: #fff;
                            }
                        }
                        z-index: 99;
                    }
                }
            }
            .dashboard-nav-favorites {
                > a {
                    .for-opened {
                        display: none;
                    }
                    .for-closed {
                        display: inline;
                    }
                }
            }
        }
    }
    .inner {
        background-color: @dashboard-bg;
        padding-top: 115px;
        .breadcrumb-outer {
            background: white;
            box-shadow: 0px 6px 44.16px 3.84px rgba(224, 237, 245, 0.35);
        }
        .breadcrumb {
            .dashboard-breadcrumbs;
        }
        margin-left: @sidebar-min-width;
        header {
            .navbar-brand {
                padding: 14px 0 13px 68px;
                margin-right: 69px;
                img {
                    width: 88px;
                }
            }
            .navbar {
                > .container {
                    padding: 0;
                }
                min-height: 115px;
                padding: 0;
                left: @sidebar-min-width;
                background-color: @dashboard-header-bg;
            }
            .navbar-collapse {
                padding-right: 72px;
                .navbar-nav {
                    margin: 0;
                    > .nav-item > .nav-link {
                        color: #ffffff;
                        &:after {
                            color: #ffffff;
                        }
                    }
                }
            }
            .search-form-widget {
                .search-field .input-group-append button {
                    color: #ffffff;
                }
                margin-right: 55px;
            }
            #navbarCollapse {
                .notification {
                    .dashboard-notification;
                    .notification-toggle {
                        color: #b7cad1 !important;
                        &:hover {
                            color: #b7cad1 !important;
                        }
                    }
                }
            }
            .settings {
                > a {
                    position: relative;
                    line-height: 21px;
                    .dashboard-header-icon;
                }
                margin: 0 10px;
            }
            .profile {
                margin-left: 15px;
                font-family: "Futura Book";
                font-size: 16px;
                .photo {
                    .dashboard-photo;
                }
                .welcome {
                    vertical-align: middle;
                    display: inline-block;
                    a {
                        font-family: "Futura Book";
                        font-size: 16px;
                        color: #ecf1f3;
                    }
                    margin-left: 10px;
                }
                .member-id {
                    display: inline-block;
                    color: #ecf1f3;
                }
            }
        }
        main {
            > .main-container {
                padding-top: 60px;
                padding-bottom: 15px;
            }
            .account-nav, .profile-nav {
                margin-bottom: .5rem;
            }
            .preview-photo {
                margin-top: 1rem;
            }
            .photo {
                .dashboard-photo;
            }
        }
    }
    .footer-block-outer {
        padding-top: 0;
        background-color: @dashboard-footer-bg;
        &:before {
            content: '';
            display: block;
            clear: both;
        }
    }
    .dashboard-grid-item {
        border-radius: 7px;
        overflow: hidden;
        background-color: #fff;
    }
    .dashboard-search {
        .dashboard-grid-item {
            overflow: inherit;
        }
    }
}

.dashboard-weather {
    .dashboard-grid-item {
        position: relative;
        .dashboard-current-weather;
    }
}

#reminders-widget {
    display: flex;
    justify-content: space-between;
    font-family: 'Futura Book', sans-serif;
    h4 {
        position: absolute;
        left: 30px;
        line-height: 70px;
        color: #fff;
        font-family: 'Futura Book';
        font-size: 20px;
        font-weight: bold;
    }
    .fc-header-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 20px 5px;
        margin: 0;
        h2 {
            font-size: 17px;
            color: #fff;
            font-family: 'Futura Book', sans-serif;
        }
        > .fc-center,
        > .fc-clear {
            display: none !important;
        }
        > .fc-right {
            > div {
                display: flex;
                align-items: flex-end;
            }
        }
        button {
            display: block;
            background: none;
            box-shadow: none;
            border: 0;
            outline: none;
            font-size: 12px;
            > span {
                .general-transition;
                color: #fff;
            }
            &:hover {
                span {
                    text-shadow: 0px 0px 10px #fff;
                }
            }
        }
    }

    .reminders-calendar {
        .mini-calendar;
    }
    .reminders-list {
        position: relative;
        width: 100%;
        padding-bottom: 50px;
        height: 362px;
        > ul {
            height: 312px;
            overflow-x: auto;
            margin-bottom: 0;
            li {
                padding: 30px 15px 10px;
                border-bottom: 2px solid #f3f3f3;
                display: flex;
                flex-direction: row;
                div:first-child {
                    display: flex;
                    align-items: baseline;
                    padding-left: 10px;
                    padding-right: 25px;
                    .point {
                        display: block;
                        border-radius: 50%;
                        height: 10px;
                        width: 10px;
                        background: #00c8e7;
                        border: 0;
                    }
                    .time {
                        padding-left: 10px;
                        padding-right: 5px;
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .time-interval {
                        color: #ccc;
                    }
                }
                div:last-child {
                    label {
                        font-weight: bold;
                        font-size: 20px;
                        a {
                            color: @main-dark;
                            &:hover {
                                color: @main-dark;
                            }
                        }
                    }
                }
            }
        }
        .new-event {
            background-color: @bg-orange;
            height: 50px;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            line-height: 40px;
            color: #fff;
            text-transform: capitalize;
            &:hover {
                background-color: darken(@bg-orange, 10%);
            }
            &:focus {
                .no-focus-shadow;
            }
        }

        #custom-scroll::-webkit-scrollbar-track {

            background-color: rgba(0, 0, 0, 0);
        }
        #custom-scroll::-webkit-scrollbar {
            width: 6px;
            background-color: rgba(0, 0, 0, 0);
        }
        #custom-scroll::-webkit-scrollbar-thumb {
            background-color: #d8d8d8;
        }

        .no-events-today {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }
    }
}

#travels-widget {
    background-image: linear-gradient(to bottom right, #1ac5fb, #1d72f1);
    position: relative;
    h4 {
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        padding: 10px 10px 0;
        font-family: 'Futura Book';
        font-size: 20px;
        font-weight: bold;
        z-index: 999;
    }
    .gm-style-mtc {
        display: none;
    }
    .gm-svpc {
        display: block !important;
    }
    .custom-bg {
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        canvas + * {
            background: #dbeffe;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            &:after {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background: #fff;
                left: -25px;
                top: -25px;
                content: '';
                position: absolute;
                border: 2px solid #fff;
                animation: mission 3s infinite;
                -moz-animation: mission 3s infinite;
                -webkit-animation: mission 3s infinite;
                -ms-animation: mission 3s infinite;
            }
        }
    }
}

.gm-style {
    .map-search {
        position: absolute;
        right: 70px;
        top: 10px;
        width: 50%;
        .map-search-input {
            border: 1px solid #fff;
            background: transparent;
            width: 100%;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px!important;
            font-family: 'Futura Book', sans-serif;
            font-size: 20px;
            font-weight: bold;
            color: #233247;
            border-radius: 2px;
            padding: 7px;
            background: #fff;

            &:active,
            &:focus {
                outline: none;
            }

            &::placeholder, /* Chrome, Firefox, Opera, Safari 10.1+ */
            &:-ms-input-placeholder, /* Internet Explorer 10-11 */
            &::-ms-input-placeholder { /* Microsoft Edge */
                color: #666;
                opacity: 1; /* Firefox */
            }
        }
        .results {
            position: relative;
            .results-list {
                position: absolute;
                top: -1px;
                left: -1px;
                right: -1px;

                z-index: 1000;
                padding: 3px 0;
                margin: 0.125rem 0 0;
                font-size: 1rem;
                color: #212529;
                text-align: left;
                list-style: none;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid rgba(0, 0, 0, 0.15);
                border-radius: 0.25rem;
                li {
                    cursor: pointer;
                    display: block;
                    width: 100%;
                    padding: 0.25rem 10px;
                    font-weight: 400;
                    color: #212529;
                    text-align: inherit;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    background-color: transparent;
                    border: 0;
                    &:hover {
                        text-decoration: none;
                        background-color: #f8f9fa;
                    }
                }
            }
        }
    }
    .member-info-window {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        .img {
            margin-right: 15px;
        }
        a {
            color: @bg-orange;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

#tide-table-widget {
    .dashboard-block;
    h4 {
        .location {
            color: #5b6b80;
            i.fa.fa-map-marker-alt {
                color: @bg-orange;
                margin-right: 5px;
            }
            font-weight: normal;
            font-size: 16px;
            float: right;
            line-height: 24px;
        }
    }
    .charts {
        margin: 0 -24px 0 -14px;
    }
}

#day-tides-widget {
    width: 100%;
    background-color: #fff;
    border-radius: 7px;
    overflow: hidden;
    .title {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        h4 {
            color: #000;
            font-family: 'Futura Book';
            font-size: 20px;
            font-weight: bold;
            padding: 25px 20px 5px 20px;
        }
        span {
            padding: 25px 20px 5px 20px;
        }
    }
    .location {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #00c8e7;
        color: #fff;
        div.under {
            display: flex;
            flex-direction: column;
            padding: 10px 20px;
        }
        i {
            padding: 10px 20px;
        }
    }
    .widget-content {
        height: 421px;
        overflow: auto;
        position: relative;
        width: 100%;
        &#custom-time-scroll::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0);
        }
        &#custom-time-scroll::-webkit-scrollbar {
            width: 6px;
            background-color: rgba(0, 0, 0, 0);
        }
        &#custom-time-scroll::-webkit-scrollbar-thumb {
            background-color: #d8d8d8;
        }
        .table {
            th,
            td {
                padding: 0.7rem 0.1rem;
            }
            tr td:first-child {
                padding-left: 20px;
            }
            tr td:last-child {
                padding-right: 10px;
            }
            .statistic {
                & + span {
                    width: 50px;
                    display: block;
                    text-align: right;
                }
            }
            span.time {
                font-size: 38px;
                font-weight: bold;
                margin: auto 5px;
            }
        }
    }
}

.dashboard-sunmoon-time {
    div.sun-time,
    div.moon-time {
        height: 230px;
        background-color: @bg-footer;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 7px;
        color: #fff;
        padding: 20px;
        label {
            font-size: 20px;
            font-weight: bold;
        }
        .under {
            position: absolute;
            bottom: 20px;
            left: 20px;
            right: 20px;
            text-align: center;
        }
    }
    div.sun-time {
        background-image: url(./../../img/frontend/riseup.png);
        //padding-left: 0;
    }
    div.moon-time {
        background-image: url(./../../img/frontend/risedown.png);
        //padding-right: 0;
    }
}

#find-members-widget {
    .dashboard-block;
    h4 {
        margin-bottom: 25px;
    }
    label {
        display: block;
        color: @bg-footer;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 4px;
    }
    select, input[type=text] {
        border-radius: 3px;
        background-color: rgb(242, 246, 247);
        border: 1px solid rgb(242, 246, 247);
        height: 40px;
        color: @bg-footer;
    }
    input[type=submit] {
        margin-top: 26px;
        display: block;
    }
    .d-flex > div {
        padding-left: 0;
        padding-right: 30px;
        &:last-child {
            padding-right: 0;
        }
    }
}

.documents-storage-widget {
    padding: 25px 30px;
    color: @main-dark;
    a {
        color: @main-dark;
        .general-transition;
        .icon {
            margin-right: 10px;
        }
        font-family: 'Futura Book';
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
        &:hover {
            text-decoration: none;
            color: @bg-orange;
        }
    }
}

#resources-widget {
    .dashboard-block;
    h4 {
        margin-bottom: 25px;
    }
    .res {
        .general-transition;
        position: relative;
        font-family: 'Futura Book';
        display: block;
        border-radius: 5px;
        width: 100%;
        height: 200px;
        margin-right: 30px;
        background-position: left;
        background-size: 120%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            background-position: right;
        }
        .title {
            font-size: 22px;
            color: #fff;
            display: inline-block;
            position: relative;
            z-index: 2;
        }
        &:hover {
            text-decoration: none;
            &:before {
                background: rgba(0, 0, 0, .3);
            }
        }
        .icon {
            font-size: 50px;
            color: #fff;
            position: relative;
            z-index: 2;
        }
        &:before {
            .general-transition;
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0);
            border-radius: 5px;
        }
    }

    .res-1 {
        background-image: url(./../../img/frontend/bg-resource1.png);
    }
    .res-2 {
        background-image: url(./../../img/frontend/bg-resource2.png);
    }
    .res-3 {
        background-image: url(./../../img/frontend/bg-resource3.png);
    }
    .res-4 {
        background-image: url(./../../img/frontend/bg-resource4.png);
    }
}

#guests-widget {
    min-height: 263px;
    .dashboard-block;
    h4 {
        margin-bottom: 28px;
    }
    ul {
        display: flex;
        justify-content: space-around;
        li {
            margin-right: 30px;
            &:last-child {
                margin-right: 0;
            }
            display: inline-block;
            a {
                .general-transition;
                transform: scale(1);
                display: block;
                text-align: center;
                &:hover {
                    .name {
                        span:first-child {
                            color: @bg-orange;
                        }
                    }
                    text-decoration: none;
                }
            }
            .photo {
                .dashboard-photo;
                .status {
                    .general-transition;
                    top: 5px;
                    right: 5px;
                    border-color: #fff;
                    opacity: 1;
                }
                padding-bottom: 15px;
            }
            .name {
                text-align: center;
                color: @main-dark;
                span:first-child {
                    transition: all .3s ease-in-out;
                    font-weight: bold;
                }
                span {
                    font-size: 17px;
                }
                small {
                    color: @grey-text;
                    font-size: 13px;
                    text-transform: capitalize;
                    min-height: 19px;
                }
                span, small {
                    display: block;
                }
            }
        }
    }
}

#weather-widget {
    .dashboard-block;
    padding: 0;
    h4 {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 68px;
        margin-bottom: 0;
        color: #5b6b80;
        background: #fff;
        text-align: center;
        font-size: 20px;
        line-height: 68px;
    }
    .search-form {
        padding: 25px 30px;
        .search-field {
            margin-bottom: 0;
            input {
                &::placeholder {
                    color: #fff;
                }
                font-size: 16px;
                border-radius: 7px;
                background: transparent;
                color: #fff;
                border: 1px solid transparent;
                &:focus {
                    border-color: #fff;
                    .no-focus-shadow;
                }
            }
        }
        .icon-search {
            color: #fff;
        }
    }
    .info-panel {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 68px + 48px;
        .weather-temperature {
            color: #fff;
            font-size: 65px;
            .celsius {
                &:before {
                    display: block;
                    content: '';
                    border: 5px solid #fff;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                }
            }
            .fahrenheit {
                &:before {
                    content: 'f';
                    color: #fff;
                    font-size: 26px;
                    font-weight: bold;
                }
            }
        }
        .weather-wind-speed {
            color: #fff;
            font-size: 65px;
            .extra {
                margin-top: -8px;
                margin-left: 5px;
                .unit {
                    font-size: 18px;
                    margin-bottom: 8px;
                    font-weight: bold;
                }
                .direction {
                    font-size: 15px;
                    font-weight: bold;
                }
            }
        }
        .weather-status {
            color: #fff;
            font-size: 80px;
            width: 75px;
            height: 45px;
        }
    }
    a.details {
        display: inline-block;
        position: absolute;
        bottom: 12px;
        right: 12px;
        font-size: 26px;
        color: #5b6b80;
        &:hover {
            color: @bg-orange;
            text-decoration: none;
        }
    }
}

.dashboard {
    .dashboard-table-view {
        padding-bottom: 45px;
        .dashboard-btn-group {
            &-top {
                > h2 {
                    float: left;
                    margin-bottom: 0;
                }
                > .btn {
                    float: right;
                    margin-left: 15px;
                }
                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }
            }
            margin-bottom: 20px;
        }
        .dashboard-table-search {
            background: #fff;
        }
        .dashboard-table {
            background: #fff;
            border-color: #dee2e6;
            font-family: 'Futura Book';
            color: @bg-footer;
            h3 {
                font-family: 'Futura Demi';
                color: @main-dark;
                a {
                    color: @main-dark;
                }
            }
            .views-count {
                float: right;
            }
            .category {
                background: #f8b42b;
                padding: 1px 10px;
                border-radius: 3px;
                color: #fff;
            }
            .no-wrap {
                white-space: nowrap;
            }
            .actions {
                a {
                    color: @bg-orange;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .dashboard-form-view {
        padding-bottom: 45px;
        h2 {
            margin-bottom: 20px;
        }
        form {
            .form-style;
            padding: 15px;
            background-color: #fff;
            .nav-tabs {
                margin: 0 0 20px 0;
            }
        }
        .actions {
            .btn {
                margin-right: 15px;
            }
            margin-top: 20px;
        }
    }
    .dashboard-list-view {
        padding-bottom: 45px;
        h2 {
            margin-bottom: 20px;
            font-family: "Futura Book" !important;
        }
        .dashboard-list {
            background: #fff;
            border-color: #dee2e6;
            font-family: 'Futura Book';
            color: @bg-footer;
            ul {
                padding: 0;
                li {
                    padding: 15px;
                    border-bottom: 1px solid #dee2e6;
                    h3 {
                        margin-bottom: 0;
                    }
                    a {
                        color: @bg-orange;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .dashboard {
        .aside-closed {
            aside {
                left: -62px;
                #sidebar-toggle {
                    right: -40px;
                    top: 45px;
                    background: #fff;
                    z-index: 10000;
                    box-shadow: 0px 0px 0px 10px #efefef;
                }
            }
            .inner {
                header {
                    .navbar {
                        left: 0!important;
                    }
                }
            }
        }
        aside {
            z-index: 9999;
            position: fixed;
            #sidebar-toggle {
                position: absolute;
                right: 15px;
            }
            &.scroll {
                overflow: scroll;
                display: block;
                height: 100vh;
            }
        }
        .inner {
            margin-left: 0;
            header {
                .navbar {
                    .search-form-widget {
                        margin-right: 0;
                    }
                    text-align: center;
                    .navbar-collapse {
                        padding-right: 0;
                    }
                    .navbar-brand {
                        padding: 10px;
                        margin-right: 0;
                        margin-left: calc(50% - 52px);
                    }
                }
            }
            #reminders-widget {
                flex-direction: column;
            }
            .dashboard-map,
            .dashboard-tide {
                margin-top: 30px;
            }
            #weather-widget {
                height: 500px;
            }
            #resources-widget {
                & > div {
                    flex-direction: column;
                    .res {
                        margin-right: 0;
                        margin-bottom: 30px;
                        background-size: cover;
                    }
                }
            }
            #find-members-widget {
                form {
                    & > div {
                        flex-direction: column;
                        & > div {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        .footer-block {
            & > div.d-flex {
                flex-direction: column;
            }
        }
    }

}

.dashboard {
    &.dashboard-marine {
        #tide-table-widget {
            .location {
                strong {
                    display: none;
                }
            }
        }
    }
}
