.dashboard-businesses {
    .dashboard-table {
        tr {
            td {
                h3 {
                    small {
                        font-size: 12px;
                        line-height: 16px;
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}

.edit-business-services.dashboard {
    .services-categories-tree, .services {
        .business-categories;
    }
}

.edit-business-services.dashboard {
    .services-categories-tree, .services {
        .business-categories;
    }
}

.edit-business-service-areas.dashboard {
    .service-areas-form {
        .form-style;
        .white-content-block;
        padding: 15px;
    }
}