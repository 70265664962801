.thread {
    a {
        color: @main-dark;
        &:hover {
            color: @main-dark;
            text-decoration: none;
        }
    }
    .user-name {
        margin: 0;
    }
    .photo {
        float: left;
        margin-right: 15px;
        .dashboard-photo;
        .status {
            border-color: #fff!important;
        }
    }
    .last-message {
        color: @bg-footer;
    }
    .messages {
        margin-top: 15px;
    }
}

.message {
    margin-bottom: 15px;
    .user-name {
        margin: 0;
    }
    .photo {
        float: left;
        margin-right: 15px;
        .dashboard-photo;
    }
    .text {
        padding: 10px;
    }
}

.form-message {
    textarea {
        width: 100%;
        height: 150px;
    }
    .counter {
        min-height: 24px;
    }
    .actions {
        width: 100%;
        button {
            float: right;
        }
    }
}