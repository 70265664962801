// Home Banner Animating Yacht
@keyframes wave-yacht {
  0% {
    left: -200px;
    transform: rotate(0deg);
  }
  20% {
    left: -205px;
    transform: rotate(1deg);
  }
  40% {
    left: -200px;
    transform: rotate(0deg);
  }
  50% {
    left: -195px;
    transform: rotate(1deg);
  }
  60% {
    left: -200px;
    transform: rotate(0deg);
  }
  80% {
    left: -205px;
    transform: rotate(2deg);
  }
  100% {
    left: -200px;
    transform: rotate(0deg);
  }
}

@-webkit-keyframes wave-yacht {
  0% {
    left: -200px;
    transform: rotate(0deg);
  }
  20% {
    left: -205px;
    transform: rotate(1deg);
  }
  40% {
    left: -200px;
    transform: rotate(0deg);
  }
  50% {
    left: -195px;
    transform: rotate(1deg);
  }
  60% {
    left: -200px;
    transform: rotate(0deg);
  }
  80% {
    left: -205px;
    transform: rotate(2deg);
  }
  100% {
    left: -200px;
    transform: rotate(0deg);
  }
}

@-moz-keyframes wave-yacht {
  0% {
    left: -200px;
    transform: rotate(0deg);
  }
  20% {
    left: -205px;
    transform: rotate(1deg);
  }
  40% {
    left: -200px;
    transform: rotate(0deg);
  }
  50% {
    left: -195px;
    transform: rotate(1deg);
  }
  60% {
    left: -200px;
    transform: rotate(0deg);
  }
  80% {
    left: -205px;
    transform: rotate(2deg);
  }
  100% {
    left: -200px;
    transform: rotate(0deg);
  }
}



// mission
@keyframes mission {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }

  10% {
    opacity: 0.8;
    transform: scale(0.1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@-webkit-keyframes mission {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1);
  }

  10% {
    opacity: 0.8;
    -webkit-transform: scale(0.1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes mission {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }

  10% {
    opacity: 0.8;
    transform: scale(0.1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}