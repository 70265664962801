.modal {
    .modal-dialog {
        .modal-header {
            .modal-title {
                font-family: 'Futura Book';
            }
        }
        .modal-body {
            .form-control {
                border: 0;
                label {
                    margin: 0;
                }
            }
        }
        .modal-footer {
            .btn {
                background-color: #f8b42b;
                color: #fff;
                border: 1px solid transparent;
                &:focus {
                    outline: 0;
                    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
                }
            }
        }
    }
    &.bootbox {
        //
    }
}